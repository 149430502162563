import React from "react"
import Layout from "../../components/layout"
import MssqlDatabasePage from "../../components/cloud/mssql-database/index"
const MssqlDatabase = () => {
  return (
    <Layout>
      <MssqlDatabasePage />
    </Layout>
  )
}

export default MssqlDatabase
