import React from "react"
import mostrusted from "../../../img/icons/most-trusted.png"
import datasupport from "../../../img/svg/database-hosting.svg"

const FeaturesMssql = () => {
  return (
    <>
      <section className="win-features">
        <h3 className="component-title text-center">
          Features of our MSSQL Cloud Database
        </h3>
        <div className="container-xl">
          <div className="row feature-wrapper">
            <div className="col-12 col-lg-6">
              <div className="feature-block">
                <h4 className="title">Most Trusted Control Panel</h4>
                <div className="description">
                  Plesk is the leading WebOps hosting platform to run, automate
                  and grow applications, websites and hosting businesses.
                </div>
                <img src={mostrusted} alt="Web Hosting" />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="feature-block">
                <h4 className="title">Database Support</h4>
                <div className="description">
                  Microsoft SQL Server (SQL2014) 12.0.2000.8
                </div>

                <img src={datasupport} alt="Web Hosting" height={74} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FeaturesMssql
