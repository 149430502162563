import React from "react"
import HeaderMssql from "./HeaderMssql"
import { Link } from "gatsby"
import PlansMSSQLDatabase from "./PlansMSSQLDatabase"
import SectionThree from "./FeaturesMssql"
import FaqMSSQL from "./FaqMSSQL"
import Seo from "../../../components/seo"

const MssqlDatabasePage = () => {
  return (
    <div className="content bg">
      <Seo
        title="MSSQL Cloud Database for Billing Software - Motherepos"
        desc="Motherepos offers MSSQL Cloud Database for Billing Software at competitive prices."
        keywords="MSSQL Cloud Database, pos database, billing software cloud database"
      />
      <HeaderMssql />
      <section className="pricing">
        <div className="container-xl">
          <div className="choose-hosting-server text-center">
            <ul className="nav nav-pills justify-content-center my-3">
              <li className="nav-item">
                <Link
                  className="nav-link btn btn-outline-dark"
                  to="/cloud/mysql-database/"
                >
                  MySQL
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link active btn btn-outline-dark "
                  to="/cloud/mssql-database/"
                >
                  MSSQL
                </Link>
              </li>
            </ul>
          </div>
          <PlansMSSQLDatabase />
        </div>
      </section>
      <SectionThree />
      <FaqMSSQL />
    </div>
  )
}

export default MssqlDatabasePage
